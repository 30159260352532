/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  Grid,
  Paper,
  Divider,
  DialogContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Switch,
  Select,
  makeStyles,
  InputBase,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArchiveIcon from '@material-ui/icons/Archive'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router'
import { format } from 'date-fns'
// import 'react-tabs/style/react-tabs.css'

import AsyncRequest from '../../components/AsyncRequest'
import styles from '../../../resources/theme/global'
import {
  applyDiscount,
  duplicateTime,
  renderRoute,
  truncateNumber,
  dateFormatedFullDate,
} from '../../../util/utils'
import { findClient, archive, fetchAttendanceInit } from '../AttendanceActions'
import Form from '../../clients/pages/Form'
import SelectField from '../../components/SelectField'
import { SNACKBAR } from '../../main/MainActions'
import consts from '../../../util/consts'

import Modal from './ModalGetInfos'
import TotalTable from './TotalTable'
import TableServices from './TableServices'
import Calendar from './Calendar'
import Historic from './Historic'
import ModalShowOpenedServices from './ModalShowOpenedServices'
// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'
import colors from '../../../assets/colors'
import {
  mdiAccountEdit,
  mdiCalendarCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCommentTextMultiple,
  mdiMagnifyExpand,
  mdiSearchWeb,
  mdiWater,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Visibility } from '@material-ui/icons'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ToastContainer } from 'react-toastify'

const useStyles = makeStyles({
  footer: {
    widht: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px',
    marginTop: '2em',
  },
  submmitButton: {
    minWidth: '99px',
    minHeight: '30px',
    borderRadius: '15px',
    padding: '2px 16px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    color: '#FFFF',
    backgroundColor: colors.primary,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.7,
    },
  },
})

function Attendance(props) {
  const { classes, onClose, data, path } = props

  const [client, setClient] = useState(null)
  const [currentService, setCurrentService] = useState()
  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState({})
  const [canCorrectFinalValue, setCanCorrectFinalValue] = useState(false)
  const [generalFinalAltered, setGeneralFinalAltered] = useState(false)
  const [form, setForm] = useState({
    services: [],
    client: [],
    general_discount: '',
    final_value_discount: 0,
    status: '',
    know_way_id: '',
    general_observations: '',
    dirty: false,
    supplier: '',
    commission: false,
    rework: false,
    payment_options: [],
  })
  const dispatch = useDispatch()
  const services = useSelector(state => state.attendance.services)
  const paymentMethods = useSelector(state => state.attendance.paymentMethods)
  const permissions = useSelector(state => state.auth.permissions)

  const [value, setValue] = useState(0)

  const styles = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const confirm = useConfirm()
  const history = useHistory()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const changeOptionType = (index, id) => {
    const items = [...form.services]

    items[index].options = { ...items[index].options, type: id }

    setForm({
      ...form,
      services: items,
    })
  }

  const updateField = useCallback(
    (name, value) => {
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  const updateClient = (name, value) => {
    setClient(value)
  }

  useEffect(() => {
    if (path === 'new') setForm({ ...form, dirty: true })
    else setCanCorrectFinalValue(true)
  }, [path])

  useEffect(() => {
    if (data && !form.dirty) {
      setForm({ ...form, ...data, dirty: true })
    }
  }, [data])

  const calculateEachRow = (services, discount) => {
    // receber cada servico e recalcular seu valor final com base na quantidade do mesmo
    if (form.dirty === true) {
      services.forEach(item => {
        item.final_value = truncateNumber(
          parseFloat(
            applyDiscount(
              item.default_value * item.quantity,
              discount
            ).toPrecision(10),
            10
          )
        )
      })
    }

    return services
  }

  const returnFinalValue = () => {
    if (form.dirty === true) {
      const generalFinalValue = form.services.reduce((total, item) => {
        let withDiscount = 0
        if (item.final_value) {
          withDiscount = truncateNumber(item.final_value)
        } else {
          withDiscount = truncateNumber(
            applyDiscount(
              item.default_value * item.quantity,
              form.general_discount || item.discount
            )
          )
        }

        return parseFloat(total) + parseFloat(withDiscount)
      }, 0)
      return generalFinalValue
    }
  }

  const correctFinalValue = () => {
    // setar os servicos novamente com valores corrigidos
    if (form.dirty === true) {
      const generalFinalValue = returnFinalValue()

      const diff =
        Math.round((form.final_value_discount - generalFinalValue) * 100) / 100

      const newServices = [...form.services]

      if (diff !== 0) {
        const position = newServices.length - 1
        newServices[position] = {
          ...newServices[position],
          final_value: (
            Number(newServices[position]?.final_value) + Number(diff)
          ).toFixed(2),
        }

        setForm({
          ...form,
          services: newServices,
        })
      }
    }
  }

  const updateFinalValue = () => {
    if (form.dirty === true) {
      let newServices = [...form.services]

      if (form.general_discount > 0 || generalFinalAltered) {
        newServices.forEach(item => {
          item.discount = form.general_discount
        })

        newServices = calculateEachRow(newServices, form.general_discount)
      }
      setForm({
        ...form,
        final_value_discount: returnFinalValue(),
        services: newServices,
      })

      if (canCorrectFinalValue) {
        correctFinalValue()
        setCanCorrectFinalValue(false)
      }
    }
  }

  useEffect(() => {
    let mounted = true
    if (currentService && mounted) {
      const newService = {
        ...currentService,
        quantity: 1,
        discount: '',
        final_time: currentService.execution_time,
        default_value_default: currentService.default_value,
        options: { type: 1, base: 0, altura: 0, diametro: 0 },
        is_rework: 0,
        tec_commission: '',
      }

      if (currentService.measureType.id === 2) {
        setOpen(true)
        setCurrentIndex(form.services.length)
      }

      const plusFinal = applyDiscount(
        newService.default_value,
        form.general_discount
      )
      newService.final_value = plusFinal
      newService.discount = form.general_discount
      newService.dateTime = Date.now()
      setForm({
        ...form,
        services: [...form.services, newService],
        final_value_discount: parseFloat(form.final_value_discount) + plusFinal,
      })
    }
    return () => (mounted = false)
  }, [currentService])

  const noDiscount = useMemo(() => {
    if (form.dirty === true) {
      return form.services.reduce((total, item) => {
        const noDiscountValue = item.default_value * item.quantity
        return parseFloat(total) + parseFloat(noDiscountValue)
      }, 0)
    }
  }, [form.services])

  useEffect(() => {
    let mounted = true
    if (mounted && form.dirty === true) {
      setForm({
        ...form,
        total_without_discount: noDiscount,
      })
    }
    return () => (mounted = false)
  }, [noDiscount])

  useEffect(() => {}, [form.total_without_discount])

  const removeChildren = index => {
    let minusFinal = 0
    minusFinal = truncateNumber(
      applyDiscount(
        form.services[index].default_value * form.services[index].quantity,
        form.general_discount
      )
    )

    const items = form.services.filter((_, key) => key !== index)

    setForm({
      ...form,
      services: items,
      final_value_discount: form.final_value_discount - minusFinal,
    })
  }

  const updateFinalTime = (index, newValue) => {
    const items = [...form.services]

    items[index] = { ...items[index], final_time: newValue }

    setForm({
      ...form,
      services: items,
    })
  }

  const updateItemFinalValue = (index, newValue) => {
    const items = [...form.services]

    items[index].final_value = newValue

    setForm({
      ...form,
      final_value_discount: returnFinalValue(),
    })
  }

  const onChangeFinalValue = () => {
    setCanCorrectFinalValue(true)
    const discount = noDiscount - form.final_value_discount

    const percent = Math.trunc(((discount * 100) / noDiscount) * 100) / 100

    if (percent <= 0) {
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'O desconto deve ser um valor maior do que zero' },
      })

      return
    }

    setForm({
      ...form,
      general_discount: percent,
    })
  }

  const updateChildren = (index, newValue, propName, json = false) => {
    const items = [...form.services]
    let final = 0

    if (json) {
      items[index].options = {
        ...items[index].options,
        [propName]: newValue,
      }
    } else {
      items[index][propName] = newValue
    }

    let generalFinal = form.final_value_discount

    if (propName === 'discount' || propName === 'quantity') {
      if (form.general_discount) {
        if (items[index].measureType.id === 2) {
          final =
            Math.round(
              (items[index].base || 1) * (items[index].altura || 1) * 100
            ) / 100
          items[index].final_value = applyDiscount(
            final * items[index].default_value,
            items[index].discount
          )

          items[index].quantity = final
        } else {
          items[index].final_value = applyDiscount(
            items[index].default_value * items[index].quantity,
            items[index].discount
          )
        }
      } else if (items[index].measureType.id === 2) {
        items[index].final_value = applyDiscount(
          items[index].quantity * items[index].default_value,
          items[index].discount
        )
      } else {
        const value = applyDiscount(
          items[index].default_value,
          items[index].discount
        )

        if (value >= 0) {
          items[index].final_value = value * items[index].quantity
        } else {
          items[index].final_value =
            items[index].default_value * items[index].quantity
        }
      }

      if (propName === 'quantity') {
        items[index].final_time = duplicateTime(
          [items[index].execution_time],
          items[index].quantity
        )
      }

      generalFinal = returnFinalValue(items)
    } else if (propName === 'base' || propName === 'altura') {
      if (propName === 'base') {
        final =
          Math.round(newValue * (items[index].options.altura || 1) * 100) / 100
        items[index].final_value = applyDiscount(
          final * items[index].default_value,
          items[index].discount
        )
      } else {
        final =
          Math.round(newValue * (items[index].options.base || 1) * 100) / 100
        items[index].final_value = applyDiscount(
          final * items[index].default_value,
          items[index].discount
        )
      }

      items[index].quantity = final

      generalFinal = returnFinalValue(items)
    } else if (propName === 'diametro') {
      final =
        Math.round(((Math.PI * items[index].options.diametro ** 2) / 4) * 100) /
        100

      items[index].final_value = truncateNumber(
        applyDiscount(final * items[index].default_value, items[index].discount)
      )

      items[index].quantity = final

      generalFinal = returnFinalValue(items)
    } else if (propName === 'default_value') {
      items[index][propName] = newValue

      items[index].final_value = applyDiscount(
        items[index].quantity * newValue,
        items[index].discount || 0
      )

      generalFinal = returnFinalValue(items)
    } else if (propName === 'is_rework') {
      if (items[index][propName]) {
        items[index].default_value = 0
      } else {
        items[index].default_value =
          items[index].serviceType?.default_value ||
          items[index].default_value_default
      }

      items[index].final_value = applyDiscount(
        items[index].default_value * items[index].quantity,
        items[index].discount || 0
      )

      generalFinal = returnFinalValue(items)
    } else if (propName === 'bar_code') {
      items[index].bar_code = newValue
    }

    setForm({
      ...form,
      services: items,
      final_value_discount: generalFinal,
    })
  }

  const updateFieldSelect = (name, value, key) => {
    const items = [...form.services]

    items[key].status_was_changed = items[key]?.status_id != value?.id
    items[key].status = value

    setForm({ ...form, services: items })
  }

  const openEdit = useCallback(index => {
    setCurrentIndex(index)
    setOpen(true)
  }, [])

  useEffect(() => {
    if (form.dirty === true) {
      updateFinalValue()
    }
  }, [form.general_discount])

  useEffect(() => {
    console.log(form?.services)
  }, [form])

  const initClient = async () => {
    try {
      const resp = await dispatch(findClient(client.id))
      const { data } = resp
      setForm({ ...form, client: data })
    } catch (e) {
      console.error(e)
    }
  }

  const confirmArchive = async id => {
    confirm({
      description: 'Deseja arquivar este orçamento?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(archive(id))
      if (resp.status === 200) {
        await dispatch(fetchAttendanceInit())
        history.push('/attendance')
      }
    })
  }

  const getServiceCommissionField = (services = [], activedCommission) => {
    const newServices = []

    services?.map(service => {
      if (!service?.commissioned_person) {
        return newServices.push(service)
      }

      let commissioned_prop = {
        commissioned_user_id: service.commissioned_person.id,
      }

      if (service.commissioned_person.type == 2) {
        commissioned_prop = {
          commissioned_client_id: service.commissioned_person.id,
        }
      } else if (service.commissioned_person.type == 3) {
        commissioned_prop = {
          commissioned_supplier_id: service.commissioned_person.id,
        }
      }

      if (!activedCommission) {
        commissioned_prop = {}
      }

      return newServices.push({
        ...service,
        ...commissioned_prop,
      })
    })

    return newServices
  }

  const onSubmit = (
    client,
    redirect = false,
    isSubmit = false,
    advanceTab = false
  ) => {
    const { onSubmit } = props

    const {
      services,
      general_discount,
      final_value_discount,
      total_without_discount,
      service_status_id,
      status,
    } = form

    const serviceStatusId = () => {
      if (form?.id && redirect && service_status_id == 1) return 2
      if (form?.id && !redirect && service_status_id == 2) return 2
      if (form.id && !redirect && service_status_id) return service_status_id

      if (!form.id && redirect) return 2
      if (!form.id && !redirect) return 1

      return service_status_id
    }

    let formmatedServices = services

    formmatedServices = getServiceCommissionField(services, form.commission)

    if (client) {
      const newValues = {
        ...form,
        client: {
          ...client,
          birthday: client.birthday
            ? format(new Date(client.birthday), 'yyyy-MM-dd')
            : null,
        },
        services: formmatedServices,
        know_way_id: client?.know_way_id,
        discount: {
          general_discount,
          final_value_discount,
        },
        total: {
          total_without_discount,
        },
        status: serviceStatusId(),
        redirect: redirect,
      }

      setForm(newValues)

      if (isSubmit) {
        if (!form.client?.name) {
          return dispatch({
            type: SNACKBAR.HARDFAIL,
            error: {
              message: 'É necessário o nome do cliente!',
            },
          })
        }

        if (!form.client?.phone) {
          return dispatch({
            type: SNACKBAR.HARDFAIL,
            error: {
              message: 'É necessário o telefone do cliente!',
            },
          })
        }

        return onSubmit(newValues, redirect)
      }

      if (advanceTab) {
        setValue(prevValue => prevValue + 1)
      }
    } else {
      const newValues = {
        ...form,
        services: formmatedServices,
        discount: {
          general_discount,
          final_value_discount,
        },
        total: {
          total_without_discount,
        },
        status: serviceStatusId(),
        redirect: redirect,
      }

      setForm(newValues)

      if (isSubmit) {
        if (!form.client?.name) {
          return dispatch({
            type: SNACKBAR.HARDFAIL,
            error: {
              message: 'É necessário o nome do cliente!',
            },
          })
        }

        if (!form.client?.phone) {
          return dispatch({
            type: SNACKBAR.HARDFAIL,
            error: {
              message: 'É necessário o telefone do cliente!',
            },
          })
        }

        return onSubmit(newValues, redirect)
      }

      if (advanceTab) {
        setValue(prevValue => prevValue + 1)
      }
    }
  }

  const fillNewServices = service => {
    const newServices = {
      ...service,
      final_value_discount: service.total_with_discount,
      services: service.services.map(_item => {
        let item = { ..._item }
        if (item?.measureType.id === 1)
          item = { ...item, quantity: parseInt(item.quantity, 10) }
        item = {
          ...item,
          id: item.serviceType.id,
          technician_default: item.serviceType.technician_value,
          status: null,
          dateTime: Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000,
          bar_code: null,
        }
        return item
      }),
    }

    setForm({
      ...form,
      ...newServices,
      status: '',
      id: null,
    })
  }

  const handleReturnTab = () => {
    const tab = value - 1

    if (tab < 0) {
      return setValue(0)
    }

    return setValue(tab)
  }

  const handleAdvanceTab = () => {
    setValue(prevValue => prevValue + 1)
  }

  const handleSave = (redirect = false) => {
    return onSubmit(null, redirect, true, false)
  }

  useEffect(() => {
    if (currentService) {
      setCurrentService()
    }
  }, [currentService])

  useEffect(() => {
    if (paymentMethods && !data?.payment_options) {
      setForm(prevState => ({
        ...prevState,
        payment_options: [],
      }))

      paymentMethods?.map(paymentMethod => {
        setForm(prevState => ({
          ...prevState,
          payment_options: [
            ...prevState?.payment_options,
            {
              id: paymentMethod.name,
              value: paymentMethod.name,
              checked: false,
            },
          ],
        }))
      })
    }
  }, [paymentMethods])

  useEffect(() => {
    dispatch(fetchAttendanceInit())
  }, [])

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{ padding: '0' }}>
        <Tabs>
          <Tab
            tabIndex={0}
            value={value}
            setValue={setValue}
            icon={mdiAccountEdit}
            label="Dados Pessoais"
            removeNavigation={false}
          />
          <Tab
            tabIndex={1}
            value={value}
            setValue={setValue}
            icon={mdiWater}
            label="Serviços"
            removeNavigation={false}
          />
          <Tab
            tabIndex={2}
            value={value}
            setValue={setValue}
            icon={mdiCommentTextMultiple}
            label="Comentários"
            removeNavigation={false}
          />
        </Tabs>
      </Grid>

      <Grid item container direction="row" xs={12}>
        <Grid item container xs={12}>
          {value === 0 && (
            <>
              <Grid item sm={5} xs={12}>
                <AsyncRequest
                  label={
                    <Grid container alignItems="center" justifyContent="center">
                      <Typography style={{ fontWeight: '700' }}>
                        Cliente já cadastrado?{' '}
                      </Typography>
                      Pesquise por nome ou telefone
                    </Grid>
                  }
                  link="client/find-by-name"
                  setForm={updateClient}
                  data={client}
                  name="client"
                  onClickButton={initClient}
                  disabledButton={Boolean(!client?.id)}
                />
              </Grid>

              <Grid item xs={12} container>
                <Form
                  data={form.client}
                  know_way_id={form?.know_way_id}
                  notShowPost
                  onSubmit={onSubmit}
                  onClose={onClose}
                  btnText="Avançar"
                  icon={mdiChevronRight}
                  submitOnBlur
                />
              </Grid>
            </>
          )}

          {value === 1 && (
            <>
              <Grid container xs={12}>
                <Grid
                  item
                  xs={12}
                  container
                  style={{ padding: '0', marginTop: '1em' }}
                >
                  <Grid item container xs={6} alignItems="flex-end">
                    <Grid item xs={6}>
                      <SelectField
                        style={{ color: 'black', marginTop: '0px' }}
                        options={services}
                        setForm={setCurrentService}
                        data={currentService || null}
                        uniqueState
                        label="Selecione um serviço"
                      />
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{ marginLeft: '24px', color: colors.textGray }}
                    >
                      Não achou um serviço?
                      <br />
                      <Typography
                        component={Link}
                        style={{
                          color: colors.secondary,
                          fontWeight: '700',
                          textDecoration: 'none',
                        }}
                        to="/service-type"
                      >
                        Clique Aqui
                      </Typography>{' '}
                      para cadastrar!
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Grid
                      item
                      container
                      xs={9}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Switch
                          checked={form.commission}
                          onChange={() =>
                            setForm({ ...form, commission: !form.commission })
                          }
                        />
                      </Grid>
                      <Grid item>Comissão</Grid>
                      {/* <Grid item>
                        <Select placeholder="Usuário" />
                      </Grid> */}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={3}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Switch
                          checked={form.rework}
                          onChange={() =>
                            setForm({ ...form, rework: !form.rework })
                          }
                        />
                      </Grid>
                      <Grid item>Retrabalho</Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  style={{ padding: '0', marginTop: '1em' }}
                >
                  <Grid item xs={12}>
                    <TableServices
                      items={form.services}
                      classes={classes}
                      removeChildren={removeChildren}
                      updateChildren={updateChildren}
                      discountDisabled={form.general_discount}
                      openEdit={openEdit}
                      updateFinalTime={updateFinalTime}
                      updateItemFinalValue={updateItemFinalValue}
                      formId={form.id}
                      updateFieldSelect={updateFieldSelect}
                      rework={form.rework}
                      commission={form.commission}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '1.5em' }}>
                    <TotalTable
                      noDiscount={noDiscount}
                      setForm={setForm}
                      form={form}
                      classes={classes}
                      onChangeFinalValue={onChangeFinalValue}
                      setGeneralFinalAltered={setGeneralFinalAltered}
                    />

                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: '1.5em' }}
                      justifyContent="flex-end"
                    >
                      <Box className={styles.footer}>
                        <IconButton
                          onClick={handleReturnTab}
                          className={styles.submmitButton}
                          style={{
                            backgroundColor: colors.grayMedium,
                            color: colors.primary,
                          }}
                        >
                          <Icon path={mdiChevronLeft} size={1} />
                          Voltar
                        </IconButton>
                        <IconButton
                          onClick={() => onSubmit(null, false, false, true)}
                          className={styles.submmitButton}
                        >
                          Avançar
                          <Icon path={mdiChevronRight} size={1} />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {value === 2 && (
            <Grid container item style={{ marginTop: '1.5em' }}>
              <Grid item xs={12}>
                <Typography>Observações gerais</Typography>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '1.5em' }}>
                <InputBase
                  value={form.general_observations}
                  onChange={e =>
                    setForm({ ...form, general_observations: e.target.value })
                  }
                  multiline
                  inputProps={{
                    style: {
                      minHeight: '269px',
                      maxHeight: '300px',
                    },
                  }}
                  fullWidth
                  style={{
                    borderRadius: '15px',
                    border: `1px solid ${colors.graylight}`,
                    minHeight: '269px',
                    padding: '8px',
                    maxHeight: '300px',
                  }}
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{ marginTop: '1.5em' }}
                justifyContent="flex-end"
              >
                <Box className={styles.footer}>
                  <IconButton
                    onClick={handleReturnTab}
                    className={styles.submmitButton}
                    style={{
                      backgroundColor: colors.grayMedium,
                      color: colors.primary,
                    }}
                  >
                    <Icon path={mdiChevronLeft} size={1} />
                    Voltar
                  </IconButton>

                  <IconButton
                    onClick={() => handleSave(true)}
                    className={styles.submmitButton}
                  >
                    <Icon path={mdiCalendarCheck} size={0.8} />
                    Agendar
                  </IconButton>

                  <IconButton
                    onClick={() => handleSave(false)}
                    className={styles.submmitButton}
                  >
                    Salvar
                    <Icon path={mdiChevronRight} size={1} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* {renderRoute(['read-schedule'], permissions) && (
          <Grid item xs>
            <Calendar classes={classes} />
          </Grid>
        )} */}

        {/* {renderRoute(['archive-attendances'], permissions) &&
          form.status?.id === consts.ORCAMENTO && (
            <Grid item xs>
              <IconButton
                aria-label="calendar"
                color="primary"
                onClick={() => confirmArchive(form.id)}
              >
                <ArchiveIcon />
              </IconButton>
            </Grid>
          )} */}

        {/* <Grid item xs>
          <Historic
            disabled={!form.client?.id}
            clientId={form.client?.id}
            canClick={form.id}
            fillNewServices={fillNewServices}
          />
        </Grid> */}
      </Grid>

      {form.client?.opened_services && (
        <ModalShowOpenedServices openedServices={form.client.opened_services} />
      )}

      <Modal
        open={open}
        handleClose={handleClose}
        classes={classes}
        currentIndex={currentIndex}
        services={form.services}
        updateChildren={updateChildren}
        changeOptionType={changeOptionType}
      />

      {/*
      <Grid
        container
        spacing={1}
        style={
          [consts.ARQUIVADO, consts.CANCELADO].includes(form.status?.id)
            ? { backgroundColor: '#ccc', opacity: 0.5, pointerEvents: 'none' }
            : {}
        }
      >
        <Grid item xs={12}>
          {form.id && (
            <Grid container>
              <Grid item xs>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  {`Atendente: ${
                    form.user?.name
                  } - Data/Hora: ${dateFormatedFullDate(form?.created_at)}`}
                </Typography>
                {form?.canceled_user && (
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.flex}
                  >
                    {`Cancelado por: ${
                      form.canceled_user?.name
                    } - Data/Hora: ${dateFormatedFullDate(form?.canceled_at)}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
      
            
            <Grid container spacing={1}> */}

      {/*  */}
      {/* <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="attendant_commnets-content"
                    id="attendant_commnets-header"
                  >
                    <Typography className={classes.heading}>
                      Comentários do Técnico
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      placeholder="Comentários do Técnico"
                      value={form.attendant_comments}
                      variant="outlined"
                      multiline
                      fullWidth
                      rows={5}
                      rowsMax={10}
                      disabled
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="payment-comments-content"
                    id="payment-comments-header"
                  >
                    <Typography className={classes.heading}>
                      Comentários do pagamento
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      placeholder="Comentários do Pagamento"
                      value={form.payment_comments}
                      variant="outlined"
                      multiline
                      fullWidth
                      rows={5}
                      rowsMax={10}
                      disabled
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid> */}
      {/* </Grid>
          </Form>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export default withStyles(styles)(Attendance)
